import { CreditCard } from "../interfaces/creditCard";
import { privateEncrypt } from "crypto";
import crmService from "./crmService";

const MA_KEY = "vnc4u01vpb8gv7vs5bf5h";
const SIB_API_URL = "https://in-automate.sendinblue.com/api/v2";

class sibService implements crmService {
  private email: string;
  private production: boolean;

  constructor(production: boolean) {
    this.email = "";
    this.production = production;
  }

  setEmail = (email: string, approved: boolean): void => {
    this.email = email;
    this.identify({
      body: {
        attributes: {
          APPROVED: approved
        }
      }
    });
  };

  photosRegistered = (): void => {
    return this.identify({
      body: {
        attributes: {
          DRIVERSLICENSE: true
        }
      }
    });
  };

  creditCardRegistered = (card: CreditCard): void => {
    return this.identify({
      body: {
        attributes: {
          CREDITCARD: "20" + card.expiryYear + "-" + card.expiryMonth + "-01"
        }
      }
    });
  };

  userLogin = (): void => {
    this.track({
      body: {
        event: "Login"
      }
    });
  };

  viewVehicle = (id: number): void => {
    this.track({
      body: {
        event: "ViewVehicle",
        eventdata: {
          ProductId: id
        }
      }
    });
  };

  createReservation = (id: number): void => {
    this.track({
      body: {
        event: "CreateReservation",
        eventdata: {
          ReservationId: id
        }
      }
    });
  };

  timeoutReservation = (id: number): void => {
    this.track({
      body: {
        event: "TimeoutReservation",
        eventdata: {
          ReservationId: id
        }
      }
    });
  };

  cancelReservation = (id: number): void => {
    this.track({
      body: {
        event: "CancelReservation",
        eventdata: {
          ReservationId: id
        }
      }
    });
  };

  startReservation = (id: number): void => {
    this.track({
      body: {
        event: "StartReservation",
        eventdata: {
          ReservationId: id
        }
      }
    });
  };

  endReservation = (
    id: number,
    length: number,
    standby: number,
    price: number
  ): void => {
    this.track({
      body: {
        event: "EndReservation",
        eventdata: {
          ReservationId: id,
          ReservationLength: length,
          ReservationStandby: standby,
          ReservationPrice: price
        }
      }
    });
  };

  openDoor = (id: number): void => {
    this.track({
      body: {
        event: "OpenDoor",
        eventdata: {
          ReservationId: id
        }
      }
    });
  };

  closeDoor = (id: number): void => {
    this.track({
      body: {
        event: "CloseDoor",
        eventdata: {
          ReservationId: id
        }
      }
    });
  };

  sendFeedback = (id: number, feedback: string): void => {
    this.track({
      body: {
        event: "SendFeedback",
        eventdata: {
          ReservationId: id,
          Feedback: feedback
        }
      }
    });
  };

  viewParking = (id: number): void => {
    this.track({
      body: {
        event: "ViewParkingPlace",
        eventdata: {
          ParkingPlaceId: id
        }
      }
    });
  };

  reserveParking = (reservationId: number, spotId: number): void => {
    this.track({
      body: {
        event: "ReserveParkingPlace",
        eventdata: {
          ReservationId: reservationId,
          ParkingPlaceId: spotId
        }
      }
    });
  };

  cancelParking = (reservationId: number, spotId: number): void => {
    this.track({
      body: {
        event: "CancelParkingPlace",
        eventdata: {
          ReservationId: reservationId,
          ParkingPlaceId: spotId
        }
      }
    });
  };

  continueWithoutImages = (id: number): void => {
    // console.log("this: ", this);
    this.track({
      body: {
        event: "ContinueWithoutImages",
        eventdata: {
          ReservationId: id
        }
      }
    });
  };

  shareApp = (method: string): void => {
    this.track({
      body: {
        event: "Share24Go",
        eventdata: {
          Method: method
        }
      }
    });
  };

  userLogout = (): void => {
    this.track({
      body: {
        event: "Logout"
      }
    });
  };

  track = (options: any) => {
    if (!this.production) return;

    (window as any).sendinblue.track(
      options.body.event,
      options.body.eventdata ? stringifyMyKeys(options.body.eventdata) : null
    );
    // console.log(
    //   "TRACK: ",
    //   options.body.event,
    //   " - ",
    //   stringifyMyKeys(options.body.eventdata)
    // );
  };

  identify = (options: any) => {
    if (!this.production) return;

    (window as any).sendinblue.identify(
      this.email,
      stringifyMyKeys(options.body.attributes)
    );
    // console.log("IDENTIFY: ", stringifyMyKeys(options.body.attributes));
  };
}

const stringifyMyKeys = (obj: []) => {
  let newObj = {} as any;
  for (const key in obj) {
    newObj["" + key] = obj[key];
  }
  return newObj;
};

export default sibService;
