import * as React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import "./index.scss";
import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import { store } from "./reduxStore";
import { BrowserRouter, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"

const Wrapper = ({ children }: any) => {
  const location = useLocation();
  React.useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// set update trigger once at beginning to retrieve initial history (afterwards retrieve only when needed)
sessionStorage.setItem('updateTaskHistory', '1');
sessionStorage.removeItem('pc-Qual'); // remove item if it exists

root.render(
    <Provider store={store}>
      <BrowserRouter>
        <Wrapper>
          <App
              authToken=""
              jwt=""
              API_URL=""
              language=""
              crmService={null}
          />
        </Wrapper>
      </BrowserRouter>
    </Provider>
);

registerServiceWorker();
