export const APP_TITLE = "24Go";
export const NONMOVEMENT_COST = 0.1;
export const MOVEMENT_COST = 0.47;
export const FAQ_LINK = "https://gonow.zendesk.com/hc/fi";
export const PARKING_USED = true;
export const EN_INTRO1 =
    "Shared 24Go cars are at your disposal 24/7.  The time-based rate includes the use of the car, fuel, and parking within the service area.";
export const EN_INTRO2 =
    "Our cars are always ready. Just book one whenever you need to and take off.";
export const EN_INTRO3 =
    "Take photographs of the car, unlock the doors, and start your reservation through the web app. Enjoy your drive!";
export const EN_INTRO4 =
    "After reaching your destination, park within the service area or use the parking spot you have reserved.";
export const EN_INTRO5 =
    "You only pay for the time you use the car. The price includes parking within our service area and 24/7 Customer Service.";
export const EN_HEADING1 = "24Go";
export const EN_HEADING2 = "Find the nearest car";
export const EN_HEADING3 = "Open the doors and set off";
export const EN_HEADING4 = "Park within the service area";
export const EN_HEADING5 = "All inclusive pricing";
export const FI_INTRO1 =
    "Yhteiskäyttöiset kaupunkiautot ovat saatavillasi kellon ympäri. Minuuttipohjaiseen all inclusive -palveluun sisältyy aina auton käyttö, polttoaine ja pysäköinti palvelualueella.";
export const FI_INTRO2 =
    "Automme ovat käytössäsi aina, kun tarvitset kyydin. Voit etsiä vapaan auton milloin tahansa ja lähteä matkaan.";
export const FI_INTRO3 =
    "Valokuvaa auto, avaa ovet ja aloita varauksesi sovelluksella. Nauti matkastasi!";
export const FI_INTRO4 =
    "Pysäköi auto perille päästyäsi palvelualueen sisälle tai ennalta varaamallesi parkkipaikalle.";
export const FI_INTRO5 =
    "Hinta määräytyy sen mukaan, kuinka pitkään käytät autoa. Hintaan sisältyy pysäköinti palvelualueella, polttoaine sekä 24/7-asiakaspalvelu.";
export const FI_HEADING1 = "24Go";
export const FI_HEADING2 = "Etsi lähin auto";
export const FI_HEADING3 = "Avaa ovet ja aja";
export const FI_HEADING4 = "Pysäköi palvelualueelle";
export const FI_HEADING5 = "All inclusive -hinnoittelu";
export const EN_LANDBOT_ID = "H-1048038-1FT0PENJXHD3HJCI";
export const FI_LANDBOT_ID = "H-1030667-V17B0JCGOJBBW157";
export const ANALYTICS_ID = "UA-131250898-1";
export const WEBSITE_HOME = "http://www.24go.fi";
export const IOS_ADD_LINK =
    "https://gonow.zendesk.com/hc/fi/articles/360023802551-Miten-saan-lis%C3%A4tty%C3%A4-verkkosovelluksen-iOS-puhelimen-aloitusn%C3%A4ytt%C3%B6%C3%B6n-";
export const ANDROID_ADD_LINK =
    "https://gonow.zendesk.com/hc/fi/articles/360023802531-Miten-saan-lis%C3%A4tty%C3%A4-verkkosovelluksen-Android-puhelimen-aloitusn%C3%A4ytt%C3%B6%C3%B6n-";
export const LOCATION_HELP_LINK =
    "https://gonow.zendesk.com/hc/fi/articles/360022074372-Miksei-sovellus-l%C3%B6yd%C3%A4-sijaintiani-";