import counterpart from "counterpart";

export class translateService {
  static translate(key: string, lang?: string): string {
    return counterpart(key, {
      fallback: counterpart(key, { locale: lang || 'en' }),
    });
  }

  static setLocale(lang: string): void {
    counterpart.setLocale(lang);
  }

  static getLocale(): string {
    return counterpart.getLocale();
  }

  static registerTranslations(lang: string, langObject: any): void {
    counterpart.registerTranslations(lang, langObject);
  }
}
