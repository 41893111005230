import * as React from "react";
import { detect } from "detect-browser";
import "./App.scss";
// import "semantic-ui.scss/semantic.min.scss";
import "./locales/index";
import { Route } from "react-router-dom";
import { Navigate, Routes } from "react-router";
// import Loadable from "react-loadable";
import * as config from "./config/themeConfig";

import { GlobalStore, store } from "./reduxStore";
import sibService from "./services/sibService";
import moment from "moment-timezone";

const AsyncIntro = React.lazy(() => import("./components/intro/intro"));
const AsyncLogin = React.lazy(() => import("./components/login/LoginContainer"));
const AsyncMap = React.lazy(() => import("./components/main/MainContainer"));
const AsyncUser = React.lazy(() => import("./components/main/MainContainer"));
const AsyncMyReservations = React.lazy(() => import("./components/main/MainContainer"));
const AsyncPayment = React.lazy(() => import("./components/main/MainContainer"));
const AsyncRefer = React.lazy(() => import("./components/main/MainContainer"));
const AsyncAtCar = React.lazy(() => import("./components/main/MainContainer"));
const AsyncTaskHistory = React.lazy(() => import("./components/main/MainContainer"));
const AsyncProfile = React.lazy(() => import("./components/main/MainContainer"));

const unauthenticatedPages: Array<string> = ["/", "/login"];
const authenticatedPages = [
  "/map",
  // "/user",
  "/payment",
  "/myreservations",
  "/atcar",
  "/refer",
  "/taskhistory",
  "/profile",
  "/profile/3"
];

interface AppProps {
  authToken: string;
  jwt: string;
  API_URL: string;
  language: string;
  crmService?: sibService | null;
  developmentVersion: boolean;
}

interface WrappedRouteProps {
  router: {
    location: {
      pathname: string,
      key: string,
      hash: string,
      search: string,
      state: string | null
    }
  }
}

interface DispatchFromProps {
  onTokenReceived: (token: string, jwt: string) => void,
}

class App extends React.Component<AppProps & WrappedRouteProps & DispatchFromProps> {
  crmService: sibService | null = null;
  constructor(props: any) {
    super(props);

    this.crmService = new sibService(
      !props.API_URL.includes("stag") && !props.API_URL.includes("test")
    );
  }

  componentDidMount() {
    moment.tz.setDefault("Europe/Helsinki");

    document.title = config.APP_TITLE;
    const browser = detect();
    // alert("REDUX Browser: " + browser.name + browser.version + browser.os);

    store.dispatch({
      type: "SET_BROWSER",
      payload: {
        ...browser,
        PWA: window.matchMedia("(display-mode: standalone)").matches,
      },
    });

    const { version } = require("../package.json");
    store.dispatch({
      type: "SET_VERSION",
      payload: version,
    });

    if (this.props.crmService === null) {
      store.dispatch({
        type: "SET_CRM",
        payload: this.crmService,
      });
    }
  }

  render() {
    const isAuthenticated =
      this.props.authToken !== "" && this.props.jwt !== "";

    let x: any = undefined;
    if (!isAuthenticated && isAuthenticatedPage(this.props.router.location.pathname)) {
      x = <Navigate replace to="/" />;
    }
    else if (
      isAuthenticated &&
      isUnauthenticatedPage(this.props.router.location.pathname)
    ) {
      x = <Navigate replace to="/map" />;
    }
    else if (
      !isAuthenticatedPage(this.props.router.location.pathname) &&
      !isUnauthenticatedPage(this.props.router.location.pathname) &&
      isAuthenticated
    ) {
      x = <Navigate replace to="/map" />;
    }
    else if (
      !isAuthenticatedPage(this.props.router.location.pathname) &&
      !isUnauthenticatedPage(this.props.router.location.pathname) &&
      !isAuthenticated
    ) {
      x = <Navigate replace to="/" />;
    }
    const search = this.props.router.location.search;
    const params = new URLSearchParams(search);
    const timeout = parseInt(params.get("timeout") || '0');
    const mode = params.get("mode");
    const fakeLocation = params.get("fakeLocation");
    const mapStyle = params.get("mapStyle");
    const code = params.get("code");
    const customerId = params.get("c") ? Number(params.get("c")) : null;
    const hash = params.get("h");

    if (timeout) store.dispatch({ type: "SET_TIMEOUT", payload: timeout });
    if (mode === "anonymous")
      store.dispatch({ type: "SET_ANONYMOUS", payload: true });
    if (fakeLocation)
      store.dispatch({ type: "SET_FAKELOCATION", payload: fakeLocation });
    if (mapStyle) store.dispatch({ type: "SET_MAPSTYLE", payload: mapStyle });
    if (code) {
      localStorage.setItem("GoNowCode", code);
    }

    if (!isAuthenticated && customerId && hash) {
      x = undefined;

      apiService
        .tryAuthHash(hash, customerId)
        .then((res) => {
          // this.setState({APIBusy: false});
          if (res.token && res.jwt) {
            localStorage.setItem("GoNowToken", res.token);
            localStorage.setItem("GoNowStore", res.jwt);
            this.props.onTokenReceived(res.token, res.jwt);
            this.props.crmService!.userLogin();
            //   if (e.phoneNumber.slice(-11) === "98785628100") {
            //     this.props.onServiceLogin();
            //   }
          } else {
            alert(translateService.translate("sendPinFeedback.pinSendFail"));
          }
        })
        .catch((res) => {
          // this.setState({ APIBusy: false });
          alert("Auth Hash Incorrect!");
          console.log("Got error!", res);
        });
    }

    if (x) return x;

    return (
      <div onClick={e => e}>
        <ScrollToTop />
        <React.Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<AsyncLogin language="fi" phoneNumber="" />} />
            <Route path="/map" element={<AsyncMap content="map" />} />
            <Route path="/" element={<AsyncIntro />} />
            {/* <Route path="/user" element={<AsyncUser content="user" />} /> */}
            <Route path="/myreservations" element={<AsyncMyReservations content="myreservations" />} />
            <Route path="/payment" element={<AsyncPayment content="payment" />} />
            <Route path="/refer" element={<AsyncRefer content="refer" />} />
            <Route path="/atcar" element={<AsyncAtCar content="atcar" />} />
            <Route path="/taskhistory" element={<AsyncTaskHistory content="taskhistory" />} />
            <Route path="/profile" element={<AsyncProfile content="profile" />} />
            <Route path="/profile/:id" element={<AsyncProfile content="profile" />} />
          </Routes>
        </React.Suspense>
      </div>
    )
  }
}

const mapStateToProps = (state: GlobalStore): AppProps => {
  // authToken becomes the prop on this container, for redux state's dbToken property
  return {
    authToken: state.login.dbToken || '',
    jwt: state.login.jwt || '',
    API_URL: state.app.API_URL,
    language: state.app.deviceLanguage,
    crmService: state.map.crmService,
    developmentVersion: state.app.developmentVersion
  };
};

function isUnauthenticatedPage(page: string): boolean {
  let x = unauthenticatedPages.filter((x) => x === page);
  return x.length > 0;
}

function isAuthenticatedPage(page: string): boolean {
  let x = authenticatedPages.filter((x) => x === page);
  return x.length > 0;
}

import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ScrollToTop from "./scrollToTop";
import { connect } from "react-redux";
import apiService from "./services/apiService";
import { translateService } from "./services/translateService";
import { Dispatch } from "redux";

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

// which actions to I want to dispatch? = mapDispatchToProps
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onTokenReceived: (token: string, jwt: string) =>
      dispatch({ type: "STORE_AUTH", payload: { token, jwt } }),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));